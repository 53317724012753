




import PublishedTendersList from '@/views/PublishedTender/views/PublishedTendersList.vue'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'PublishedBuyingTendersList',
  components: { PublishedTendersList },
})
